import { Attachment, UUID } from '@/types/types';
import { ApiQueryKey } from '@/configs/ApiQueryKey';
import { ProductType } from './ProductTypeApi';
import EntityApi, { BaseEntity } from './EntityApi';

export interface QuantityPerDimension {
	width: number;
	height: number;
	depth: number;
	quantity: number;
}

export interface FormQuestion {
	questionId: UUID;
	name: string;
	questionGroup: string;
}

export interface FormAnswer {
	answerId: UUID;
	question: FormQuestion;
	answer: string;
	files: Attachment[];
}

export enum FormStatus {
	NEW_FORM = 'NEW_FORM',
	IN_VALUATION = 'IN_VALUATION',
	IN_PRODUCTION = 'IN_PRODUCTION',
	COMPLETED = 'COMPLETED',
}

export interface UserForm extends BaseEntity {
	orderId: string;
	productType: ProductType;
	userData: {
		name: string;
		companyName: string;
		NIP: string;
		city: string;
		postalCode: string;
		address: string;
		email: string;
		phone: string;
	};
	shippingData: {
		city: string;
		postalCode: string;
		address: string;
	};
	quantity: number;
	orderDate: string;
	note: string;
	formAnswers: FormAnswer[];
	quantityPerDimension: QuantityPerDimension[];
	createdAt: string;
	status: FormStatus;
	externalOrderID: string;
}

class FormApi extends EntityApi<UserForm> {
	constructor() {
		super(ApiQueryKey.forms);
	}
}

export default new FormApi();
